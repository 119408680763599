<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/stock2' }"
        >库存管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>非生鲜商品库</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background: #ffffff; border-radius: 5px;padding: 20px;box-sizing: border-box;margin-top: 20px;">
      <div class="shop-screen">
        <!-- <div class="tips">
          <i
            class="el-icon-warning"
            style="font-size: 18px; margin-right: 5px"
          ></i>
          <div>门店账号不支持导入功能,请登录连锁账号</div>
        </div> -->
          <el-button
          v-if="IsUP"
          type="primary"
          size="small"
          style="background: #4781d9; border: 0; margin-right: 10px"
          @click="addShow = true"
          >新增+</el-button
        >
          <el-button
          v-if="IsUP"
          size="small"
          type="primary"
          style="background: #4781d9; border: 0; margin-right: 10px"
          class="btn-item"
          @click="importBtn"
          ><span>批量导入</span><i class="iconfont icon-piliangdaoru"></i
        ></el-button>
        <a :href="href+'?'+'timer='+timer" style="text-decoration: none">
          <el-button
            v-if="IsUP"
            type="primary"
            size="small"
            style="background: #4781d9; border: 0"
            class="btn-item"
            ><span>非生鲜模板下载</span><i class="iconfont icon-xiazai"></i
          ></el-button>
        </a>
      </div>
      <el-form
        ref="form"
        :model="seachForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="商品名称">
          <el-input
            v-model="seachForm.goodsName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品条码">
          <el-input
            v-model="seachForm.goodsNum"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        type="index"
        :header-cell-style="{ background: '#CD0550', color: '#fff' }"
      >
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="good_name" label="商品名称"/>
        <el-table-column prop="good_code" label="商品条码"/>
        <el-table-column prop="good_price" label="商品单价"/>
        <el-table-column prop="supermarket_category_name" label="商品类型"/>
        <el-table-column prop="created_at" label="创建时间"/>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div style="position: relative; display: inline">
              <i
                style="color: #3ca6f0"
                @click="operation(scope.row)"
                class="iconfont icon-bianji"
              >
              </i>
              <div v-if="scope.row.num == num" class="operation">
                <p @click.stop="change(scope.row)" :class="active == 1 ? 'active' : ''">
                  编辑
                </p>
                <p @click.stop="delgoods" :class="active == 2 ? 'active' : ''">
                  删除
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
    <div class="add-box" v-if="addShow" @click="addShow = false">
      <div class="add-Member" @click.stop="">
        <div style="display: flex; justify-content: space-between">
          <div class="add">新增</div>
          <div class="close" @click="addShow = false">x</div>
        </div>
        <el-form
          :model="addGoodsFrom"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <!-- <el-form-item
            label="商品分类"
            prop="category_id"
            :rules="[{ required: true, message: '商品分类不能为空' }]"
          >
            <el-select
              v-model="addGoodsFrom.category_id"
              placeholder="请选择商品分类"
              style="width: 440px"
              size="small"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item
            label="商品名称"
            prop="good_name"
            :rules="[{ required: true, message: '商品名称不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_name"
              autocomplete="off"
              placeholder="请选择商品名称"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="条形码编号"
            prop="good_code"
            :rules="[{ required: true, message: '商品条形码编号不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_code"
              autocomplete="off"
              placeholder="请输入条形码编号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品原价"
            prop="good_price"
            :rules="[{ required: true, message: '商品原价不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_price"
              autocomplete="off"
              placeholder="请输入商品原价"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品类型"
            prop="supermarket_category_id"
            :rules="[{ required: true, message: '商品类型不能为空' }]"
          >
            <el-select
              v-model="addGoodsFrom.supermarket_category_id"
              placeholder="请选择商品类型"
              style="width: 440px"
              size="small"
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.category_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item
            label="商品数量"
            prop="good_quantity"
            :rules="[{ required: true, message: '商品数量不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_quantity"
              autocomplete="off"
              placeholder="请输入商品数量"
            ></el-input>
          </el-form-item> -->
          <el-form-item
            style="display: flex; justify-content: flex-end; margin-top: 20px"
          >
            <el-button
              type="primary"
              @click="submitForm(addGoodsFrom)"
              size="small"
              style="background: #cd0550; border: none"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      title="批量录入"
      :visible.sync="dialogVisible1"
      @close="goodsForm = {}"
      width="30%"
    >
      <div>
        <!-- <div class="goodstype" >
          <div>商品分类: </div>
          <div  v-for="(item, index) in typeList" :key="index">{{item.title}}</div>
        </div> -->
        <el-upload
          drag
          multiple
          class="upload-demo"
          :action="httpUrl + 'api/v1/store/chainGood/excelStore'"
          :headers="{ Authorization: 'Bearer ' + tk }"
          :before-upload="beforeAvatarUpload"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :data="{ category_id: goodsForm.goodsType }"
          accept=".xls,.xlsx"
          :auto-upload="false"
          ref="upload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="submitUpload"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="编辑"
      :visible.sync="changeGoodsShow"
      width="30%"
    >
      <el-form
          :model="addGoodsFrom"
          ref="addGoodsFrom"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="商品名称"
            prop="good_name"
            :rules="[{ required: true, message: '商品名称不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_name"
              autocomplete="off"
              placeholder="请选择商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="条形码编号"
            prop="good_code"
            :rules="[{ required: true, message: '商品条形码编号不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_code"
              autocomplete="off"
              placeholder="请输入条形码编号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品原价"
            prop="good_price"
            :rules="[{ required: true, message: '商品原价不能为空' }]"
          >
            <el-input
              size="small"
              v-model="addGoodsFrom.good_price"
              autocomplete="off"
              placeholder="请输入商品原价"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品类型"
            prop="supermarket_category_id"
            :rules="[{ required: true, message: '商品类型不能为空' }]"
          >
            <el-select
              v-model="addGoodsFrom.supermarket_category_id"
              placeholder="请选择商品类型"
              style="width: 440px"
              size="small"
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.category_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="display: flex; justify-content: flex-end; margin-top: 20px"
          >
            <el-button
              type="primary"
              @click="changeGoods(addGoodsFrom)"
              size="small"
              style="background: #cd0550; border: none"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui"
import local from "@/utils/local.js"
import {getNofreshListGoodList_api1,getChainGoodAdd_api1,} from "@/api/store/shop.js"
import { chainGoodDelete_api1, goodSeriesCategory,freshChangechainGood1,getAllShopCategory_api} from "@/api/store/goods.js"
import { imgUrl, httpUrl } from "@/utils/http.js"
export default {
  data() {
    return {
      judge:false,
      timer: "",
      imgUrl: imgUrl,
      httpUrl: httpUrl,
      goodsForm: {
        goodsType: "",
      },
      seachForm: {
        goodsName: "",
        goodsType: "",
        goodsNum: "",
      },
      addGoodsFrom: {
        // category_id: "",
        good_name: "",
        good_code: "",
        good_price: "",
        // good_quantity: "",
        supermarket_category_id:null
      },
      changeGoodsShow:false,
      currentPage: 1,
      pageSizes: [10, 20, 50],
      pageSize: 10,
      total: 0,
      tableData: [],
      imageUrl: "",
      num: 0,
      active: 1,
      addShow: false,
      tk: "",
      href: "",
      dialogVisible: false,
      num: 0,
      active: 1,
      goodsId: "",
      dialogVisible1: false,
      typeList: "",
      fresh_template_url: "",
      IsUP:0,
      categoryList:[]
    }
  },
  methods: {
    getAllShopCategory(){
      getAllShopCategory_api().then((res) => {
       this.categoryList=res.data.data
       this.categoryList.unshift({
        id:0,
        category_name:'无'
       })
      })
    },
    //搜索
    search() {
      this.getChainGoodList()
    },
    emptySearch() {
      (this.currentPage = 1),
      (this.seachForm = {
        shopName: "",
        goodsName: "",
        goodsType: "",
        goodsNum: "",
      })
      this.getChainGoodList()
    },
    //导入
    submitUpload() {
      this.$refs.upload.submit()
    },
    //显示导入页面
    importBtn() {
      this.dialogVisible1 = true
    },
    //预览图片
    bigImg() {
      this.dialogVisible = true
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getChainGoodList()
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getChainGoodList()
    },
    //去编辑
    change(data) {
      this.changeGoodsShow=true
      this.addGoodsFrom=data
      this.active=1
    },
    changeGoods(){
      this.addGoodsFrom.good_id=this.goodsId
      this.$refs.addGoodsFrom.validate(async (valid) => {
        if (valid) {
          let res = await freshChangechainGood1(this.addGoodsFrom)
          if (res.data.code == 0) {
            this.$message({
              message: "修改商品成功!",
              type: "success",
            })
            this.changeGoodsShow= false
            // this.addGoodsFrom = {}
            this.num=0
            this.getChainGoodList()
          }else{
            this.$message({
              message: res.data.msg,
              type: "success",
            })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    //删除
    delgoods() {
      this.active = 2
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        chainGoodDelete_api1({ good_id: this.goodsId }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功!",
              type: "success",
            })
            this.getChainGoodList()
            this.num = 0
          }
        })
      })
      // .catch(() => {
      //   this.$message({
      //     type: "info",
      //     message: "已取消删除",
      //   })
      // })
    },

    //显示操作页面
    operation(row) {
      local.set("goodsInfo", JSON.stringify(row))
      if (this.num == row.num) {
        this.num = 0
      } else {
        this.num = row.num
      }
      // this.shopId = row.id
      // this.shopInfo = row
      // this.shopForm.shopName = row.name
      // this.shopForm.phone = row.shop_phone
      // this.shopForm.shopText = row.introduce
      // this.imageUrl = row.logo
      // this.shopname = row.name
      local.set("shopId", row.id)
      this.goodsId = row.id
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      // this.imageUrl = res.data
    },
    //上传文件之前的钩子
    beforeAvatarUpload1(file) {
      const isLt500k = file.size / 1024 < 500
      if (!isLt500k) {
        this.$message.error("上传商品图片大小不能超过 500kb!")
      }
      return isLt500k
    },
    //获取库存商品列表
    async getChainGoodList() {
      let res = await getNofreshListGoodList_api1({
        page: this.currentPage,
        limit: this.pageSize,
        good_code: this.seachForm.goodsNum,
        good_name: this.seachForm.goodsName,
      })
      this.tableData = res.data.data.list
      this.total = res.data.data.total_count
      this.href = res.data.data.template_url
      this.fresh_template_url = res.data.data.fresh_template_url
      this.tableData.forEach((item, index) => {
        item.num = index + 1
      })
    },
    //新增
    submitForm() {
      this.$refs.numberValidateForm.validate(async (valid) => {
        if (valid) {
          let res = await getChainGoodAdd_api1(this.addGoodsFrom)
          if (res.data.code == 0) {
            this.$message({
              message: "添加商品成功!",
              type: "success",
            })
            this.addShow = false
            this.addGoodsFrom = {}
            this.getChainGoodList()
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    //上传
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isLt1M) {
        this.$message.error("上传文件大小不能超过 1MB!")
      } else {
        this.loadingInstance = Loading.service({
          text: "文件上传中,请稍后~",
        })
      }
      return isLt1M
    },
    //上传成功
    uploadSuccess(res) {
      this.loadingInstance.close()
      if (res.code == 0) {
        this.$refs.upload.clearFiles()
        this.$message({
          message: "导入成功!",
          type: "success",
        })
        this.dialogVisible1 = false
        this.getChainGoodList()
      } else {
        this.$refs.upload.clearFiles()
        this.$message({
          message: res.msg,
          type: "error",
        })
      }
    },
    //上传失败
    uploadError(res) {
      this.$message.error("导入失败,请检查格式.内容是否正确!")
      this.loadingInstance.close()
      this.$refs.upload.clearFiles()
    },
  },
  created() {
    this.IsUP = local.get("IsUp")
    this.getChainGoodList()
    this.getAllShopCategory()
    this.tk = local.get("tk")
    goodSeriesCategory().then((res) => {
      this.typeList = res.data.data
    })
    let timer = new Date()
    this.timer = timer.getTime()
  },
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  display: flex;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
  flex-wrap: wrap;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.add-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.add-Member {
  width: 600px;
  // height: 580px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  box-sizing: border-box;
}
.close {
  cursor: pointer;
}
.add {
  border-bottom: #cd0550 3px solid;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #cd0550;
}
.demo-ruleForm {
  margin-top: 40px;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  z-index: 0;
}
.btn-item {
  display: flex;
  align-items: center;
  i {
    font-size: 14px;
    margin-left: 3px;
  }
}
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 260fpx;
  height: 160px;
}
.el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 260px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.avatar {
  width: 346px;
  height: 160px;
  display: block;
}
.imgTips {
  color: #cd0550;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  p {
    line-height: 26px;
  }
}
.seeImg {
  cursor: pointer;
  width: 70px;
  height: 36px;
  background: #cd0550;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
/deep/.el-upload-dragger {
  width: 300px;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-weight: bold;
  color: #cd0550;
}
/deep/.el-button--primary {
  background: #cd0550;
  border: none;
}
/deep/.el-form-item__content {
  display: flex;
}
.goodstype {
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
  flex-wrap: wrap;

  div {
    margin-right: 15px;
    line-height: 22px;
  }
}
.tips {
  height: 20px;
  border-radius: 5px;
  background: #FEF0F0 ;
  color: #F56C6C;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  border: 1px solid #FBB2B2;
}
</style>
<style>
.el-myBtn {
  background-color: #cd0550 !important;
  border: 1px solid #cd0550 !important;
}
</style>