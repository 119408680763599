import request from "@/utils/request.js"

//修改生鲜商品

export const freshChangechainGood=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/chainGood/freshChange',
    data: data
  });
  } 

//获取商品列表
export const getGoodsList_api1=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/good/list',
    params,
  });
  } 
 //获取所有的商品分类
export const getAllShopCategory_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/chainGood/getAllShopCategory',
    params,
  });
  } 
//获取系列商品列表
export const goodSerieslist=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/store/goodSeries/list',
      params,
    });
    } 
    //编辑商品
 export const freshChangechainGood1=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/store/chainGood/change',
      data: data
    });
    }
    //删除商品
  export const chainGoodDelete_api1=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/store/chainGood/delete',
      data: data
    });
    } 
 //获取商品分类

 export const goodSeriesCategory=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/goodSeries/category',
    params,
  });
  } 

//修啊该商品库商品

export const changeChainGood=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/chainGood/change',
    data: data
  });
  } 